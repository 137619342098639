import { useDisclosure } from '@mantine/hooks';
import { Alert, Group } from '@mantine/core';
import './Telehealth.css';
import { formatSearchQuery, Operator, SearchRequest } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';

interface TelehealthAlertProps {
  handleStartCall: (appointmentId: string) => void;
}

const TelehealthAlert = ({ handleStartCall }: TelehealthAlertProps): JSX.Element => {
  const [opened, { close }] = useDisclosure(true);
  const medplum = useMedplum();
  const [patientInfo, setPatientInfo] = useState<{ id: string; name: string; appointmentId: string }>({
    id: '',
    name: '',
    appointmentId: '',
  });

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData().catch(console.error);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  if (!opened) {
    return <></>;
  }

  const fetchData = async (): Promise<void> => {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0)).toISOString();

    //   ! Appointment to be start 5 min - Next 1 Hr
    // const now = new Date();
    // const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);
    // const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000);

    const filters = [
      { code: 'service-type', operator: Operator.EQUALS, value: 'Telehealth' },
      { code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: startOfDay },

      //   ! Appointment to be start 5 min
      //   { code: 'start', operator: Operator.GREATER_THAN_OR_EQUALS, value: now.toISOString() },
      //   { code: 'start', operator: Operator.LESS_THAN, value: fiveMinutesLater.toISOString() },
    ];

    const query: SearchRequest = {
      resourceType: 'Appointment',
      filters: filters,
      total: 'accurate',
    };

    try {
      const response: any = await medplum.readResource('Appointment', formatSearchQuery(query));
      extractPatientDetails(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const extractPatientDetails = (data: any): void => {
    if (!data || data.length === 0) {
      return;
    }

    const participant = data.entry?.[0].resource?.participant?.find(
      (p: any) => p?.actor?.reference?.startsWith('Patient/')
    );

    const patientId = participant?.actor?.reference?.split('/')[1];
    const patientName = participant?.actor?.display;
    const appointmentId = data.entry?.[0].resource?.id;

    setPatientInfo({ id: patientId, name: patientName, appointmentId });
  };

  return (
    <Alert
      display={patientInfo?.id ? 'block' : 'none'}
      variant="light"
      bg="violet"
      className="telehealth-alert"
      onClose={close}
      icon={<img src="../../img/icons/video-recorder.svg" />}
      withCloseButton
    >
      <Group position="apart" align="center" className="alert-content">
        <div className="message-container">
          <span>
            You have an appointment with{' '}
            <span
              onClick={() => (window.location.href = `/Patient/patient-dashboard/${patientInfo?.id}`)}
              className="doctor-name-txt"
            >
              {patientInfo?.name}
            </span>
          </span>
          <div className="subtitle">Please prepare for the call</div>
          <Group className="button-group">
            <span onClick={close} className="dismiss-button">
              Dismiss
            </span>
            <span className="call-button" onClick={() => handleStartCall(patientInfo.appointmentId)}>
              Call now
            </span>
          </Group>
        </div>
      </Group>
    </Alert>
  );
};

export default TelehealthAlert;
