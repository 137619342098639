import React, { useEffect, useState } from 'react';
import { Loading, useMedplumContext } from '@medplum/react';
import { Grid, Paper } from '@mantine/core';
import { useParams } from 'react-router-dom';
import TitleComponent from '../TitleComponent';
import ZoomMeeting from './Zoom/video/ZoomMeeting';
import ZoomContext from './Zoom/context/zoom-context';
import ZoomVideo from '@zoom/videosdk';
import { getZoomSignature } from '../../fhirApi';
import { Patient } from '@medplum/fhirtypes';
import PatientDetails from './PatientDetails';
// import { MeetingDetailTabs } from './MeetingDetailTabs';ss

interface MeetingDetails {
  signature: string;
  topic: string;
  name: string;
  password: string;
}

const Meeting: React.FC = () => {
  const { medplum, profile } = useMedplumContext();
  const { id: appointmentId } = useParams<{ id: string }>();
  const [appointment, setAppointment] = useState<any>();
  const [meetingDetails, setMeetingDetails] = useState<MeetingDetails | null>(null);
  const [displayMeeting, setDisplayMeeting] = useState(false);
  const zmClient = ZoomVideo.createClient();
  const activeUserType = profile?.resourceType;
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [transcription, setTranscription] = React.useState<string | undefined>('');
  const [isRightSectionVisible, setIsRightSectionVisible] = useState(false);

  const fetchAppointmentDetails = async (appointmentId: string): Promise<void> => {
    try {
      const appointment = await medplum.readResource('Appointment', appointmentId);
      if (!appointment) {
        throw new Error('Appointment not found');
      }

      const participants = appointment?.participant?.map((part: any) => ({
        reference: part.actor.reference,
        display: part.actor.display,
      }));

      const patientId = participants?.find((part: any) => part.reference.includes('Patient'))?.reference?.split('/')[1];
      const patient = await medplum.readResource('Patient', patientId);
      setPatient(patient);
      setAppointment(appointment);
    } catch (error) {
      console.error('Error fetching appointment details:', error);
    }
  };

  const getSignatureDetails = async () => {
    const role = activeUserType === 'Patient' ? 0 : 1;
    const body = {
      appointmentId: appointmentId,
      role_type: role,
    };

    try {
      const session = await getZoomSignature(medplum, body);
      if (session) {
        const { sdkJWT } = session;

        const participants = appointment.participant?.map((part: any) => ({
          reference: part.actor.reference,
          display: part.actor.display,
        }));

        var name: string;
        if (participants) {
          name = activeUserType === 'Practitioner' ? participants[1]?.display : participants[0]?.display;
        }

        setMeetingDetails((prevDetails: any) => ({
          ...prevDetails,
          signature: sdkJWT,
          topic: appointmentId,
          name: name,
        }));

        setDisplayMeeting(true);
      }
    } catch (error) {
      console.error('Error joining Zoom meeting:', error);
    }
  };

  useEffect(() => {
    if (appointmentId) {
      fetchAppointmentDetails(appointmentId).catch((err) => {
        console.error('Error fetching appointment details', err);
      });
    }
  }, [appointmentId, medplum]);

  useEffect(() => {
    if (appointment) {
      getSignatureDetails().catch((err) => {
        console.error('Error getting signature details', err);
      });
    }
  }, [appointment]);

  const renderZoomMeeting = () => {
    if (!displayMeeting || !zmClient || !meetingDetails) {
      return <Loading />;
    }

    return (
      <ZoomContext.Provider value={zmClient}>
        <ZoomMeeting
          meetingArgs={{
            topic: meetingDetails.topic,
            signature: meetingDetails.signature,
            name: meetingDetails.name,
            webEndpoint: 'zoom.us',
            setIsRightSectionVisible: () => setIsRightSectionVisible(true),
          }}
          setTranscription={setTranscription}
          appointment={appointment}
        />
      </ZoomContext.Provider>
    );
  };

  return (
    <div>
      <TitleComponent title="Meeting" />
      <Paper className="resource-tab-panel patient-list">
        <Grid>
          <Grid.Col xs={12} sm={isRightSectionVisible ? 6 : 12}>
            {renderZoomMeeting()}
          </Grid.Col>
          {isRightSectionVisible && (
            <Grid.Col xs={12} sm={6}>
              {patient && appointment ? (
                <PatientDetails
                  patient={patient}
                  appointment={appointment}
                  transcription={transcription || ''}
                  setIsRightSectionVisible={() => setIsRightSectionVisible(false)}
                />
              ) : (
                <Loading />
              )}
            </Grid.Col>
          )}
        </Grid>

        {/* <Title fs='20' fw={600} mt="xl">Other Details</Title>

                <Grid>
                    <Grid.Col span={12}>
                        {patient && <MeetingDetailTabs patient={patient} activeMeeting={isActiveMeeting} />}
                    </Grid.Col>
                </Grid> */}
      </Paper>
    </div>
  );
};

export default Meeting;
