import { Box, Flex, Group, Image, Input, NativeSelect, Text } from '@mantine/core';
import { IconCircleMinus, IconCirclePlus, IconRefresh } from '@tabler/icons-react';
import './Components.css';
import { useState } from 'react';

interface TelehealthTranscriptionProps {
  transcription: string;
}

const TelehealthTranscription = ({ transcription }: TelehealthTranscriptionProps) => {
  const [fontSize, setFontSize] = useState(14);
  return (
    <Box w={'100%'}>
      <Group display={'flex'} pt={8} pb={12}>
        <Flex align={'center'}>
          <Text size={'xs'} color="#667085" fw={500}>
            Converted to
          </Text>

          <NativeSelect size={'xs'} data={['English']} style={{ border: 'none' }} />
          <IconRefresh size={16} color="#9552E8" />
        </Flex>

        <Flex align={'center'} gap={16}>
          <Text size={'xs'} color="#667085" fw={500}>
            Font Size
          </Text>
          <Box ml={5} className="flex-center gap-8">
            <IconCirclePlus
              cursor={'pointer'}
              color="#667085"
              size={13}
              onClick={() => {
                setFontSize(fontSize + 1);
              }}
            />{' '}
            <Text size={'xs'} fw={700}>
              {fontSize}
            </Text>{' '}
            <IconCircleMinus
              cursor={'pointer'}
              size={13}
              color="#667085"
              onClick={() => {
                setFontSize(fontSize - 1);
              }}
            />
          </Box>
        </Flex>
      </Group>

      <Group>
        <Input
          icon={<Image src="../../img/icons/search-icon.svg" width={15} height={15} />}
          radius={'8px'}
          placeholder="Search"
          size="sm"
          w={'85%'}
          className="transcription-search-input"
        />
        <Image width={24} height={24} src={'../../img/icons/roller-brush.svg'} />
      </Group>

      <Box className="transcription-text">
        {transcription
          .split('\n')
          .map((line, index) => {
            const speakerMatch = line.match(/^(Speaker [12] \([^)]+\):)\s*(.*)$/);

            if (speakerMatch) {
              const [, speakerPrefix, restOfText] = speakerMatch;

              return (
                <Text color="#101828" className='transcription-text-line' key={index} size={fontSize} >
                  <span className="speaker-prefix">{speakerPrefix}</span>{' '}
                  <Text fw={400} tt="capitalize" span>
                    {restOfText[0]}
                  </Text>{' '}
                  <Text fw={400} span>
                    {restOfText}
                  </Text>
                  <br />
                </Text>
              );
            }

            return null;
          })
          .filter(Boolean)}
      </Box>
    </Box>
  );
};

export default TelehealthTranscription;
