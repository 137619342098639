import { useState, useEffect, useMemo } from 'react';
import { MediaDevice } from '../video-types';
import { Button, Tooltip, Modal } from '@mantine/core';
import CRCCallOutModal from './crc-call-out-modal';
import { useAudioLevel } from '../hooks/useAudioLevel';
import { IconMicrophone, IconMicrophoneOff, IconPhone, IconPhoneOff } from '@tabler/icons-react';

interface MicrophoneButtonProps {
  isStartedAudio: boolean;
  isMuted: boolean;
  isSupportPhone?: boolean;
  isMicrophoneForbidden?: boolean;
  disabled?: boolean;
  audio?: string;
  phoneCountryList?: any[];
  onMicrophoneClick: () => void;
  onMicrophoneMenuClick: (key: string) => void;
  onPhoneCallClick?: (code: string, phoneNumber: string, name: string, option: any) => void;
  onPhoneCallCancel?: (code: string, phoneNumber: string, option: any) => Promise<any>;
  className?: string;
  microphoneList?: MediaDevice[];
  speakerList?: MediaDevice[];
  activeMicrophone?: string;
  activeSpeaker?: string;
  phoneCallStatus?: { text: string; type: string };
  isSecondaryAudioStarted?: boolean;
}

const MicrophoneButton = (props: MicrophoneButtonProps) => {
  const {
    isStartedAudio,
    isMuted,
    audio,
    className,
    // microphoneList,
    // speakerList,
    // phoneCountryList,
    // phoneCallStatus,
    disabled,
    isMicrophoneForbidden,
    // isSecondaryAudioStarted,
    onMicrophoneClick,
    // onMicrophoneMenuClick,
    // onPhoneCallClick,
    // onPhoneCallCancel,
  } = props;

  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [isCrcModalOpen, setIsCrcModalOpen] = useState(false);
  const level = useAudioLevel();
  const tooltipText = isStartedAudio ? (isMuted ? 'Unmute' : 'Mute') : 'Start Audio';

  const audioIcon = useMemo(() => {
    if (isStartedAudio) {
      return isMuted ? (
        audio === 'phone' ? (
          <IconPhoneOff color='#868686' stroke={1.5}/>
        ) : level !== 0 ? (
          <IconMicrophoneOff color='#868686' stroke={1.5}/> //animated
        ) : (
          <IconMicrophoneOff color='#868686' stroke={1.5} />
        )
      ) : audio === 'phone' ? (
        <IconPhone color='#868686' stroke={1.5}/>
      ) : (
        <IconMicrophone color='#868686' stroke={1.5} />
      );
    } else {
      return isMicrophoneForbidden ? <IconMicrophoneOff color='#868686' stroke={1.5}/> : <IconMicrophoneOff color='#868686' stroke={1.5}/>;
    }
  }, [level, audio, isMuted, isMicrophoneForbidden, isStartedAudio]);

  // Add microphone settings if needed
  // const handleMenuClick = (key: string) => {
  //   onMicrophoneMenuClick(key);
  // };

  // const menuItems = [
  //   microphoneList?.length &&
  //     audio !== 'phone' && (
  //       <Menu >
  //         {microphoneList.map((i) => (
  //           <Menu.Item key={`microphone|${i.deviceId}`} onClick={() => handleMenuClick(`microphone|${i.deviceId}`)}>
  //             {i.label}
  //           </Menu.Item>
  //         ))}
  //       </Menu>
  //     ),
  //   speakerList?.length &&
  //     audio !== 'phone' && (
  //       <Menu>
  //         {speakerList.map((i) => (
  //           <Menu.Item key={`speaker|${i.deviceId}`} onClick={() => handleMenuClick(`speaker|${i.deviceId}`)}>
  //             {i.label}
  //           </Menu.Item>
  //         ))}
  //       </Menu>
  //     ),
  //   <Menu.Item key="secondary_audio" onClick={() => handleMenuClick('secondary_audio')}>
  //     {isSecondaryAudioStarted ? 'Stop Secondary Audio' : 'Start Secondary Audio'}
  //   </Menu.Item>,
  //   audio !== 'phone' && (
  //     <Menu.Item key="statistic" onClick={() => handleMenuClick('statistic')}>
  //       Audio Statistic
  //     </Menu.Item>
  //   ),
  //   <Menu.Item key="leave_audio" onClick={() => handleMenuClick('leave_audio')}>
  //     {audio === 'phone' ? 'Hang Up' : 'Leave Audio'}
  //   </Menu.Item>,
  // ].filter(Boolean);

  useEffect(() => {
    if (isStartedAudio) {
      setIsPhoneModalOpen(false);
    }
  }, [isStartedAudio]);

  return (
    <div className={className}>
      <Tooltip label={tooltipText} position="top" withArrow>
        {isStartedAudio ? (
          <Button className='vc-button' variant="filled" radius="xl" size="sm" ml="0 !important" disabled={disabled} onClick={onMicrophoneClick}>
          {audioIcon}
        </Button>
        ) : (
          <Button className='vc-button' variant="filled" radius="xl" size="sm" ml="0 !important" onClick={onMicrophoneClick} disabled={disabled}>
            {audioIcon}
          </Button>
        )}
      </Tooltip>

      <Modal opened={isPhoneModalOpen} onClose={() => setIsPhoneModalOpen(false)} title="Phone Call">
        {/* <CallOutModal
          visible={isPhoneModalOpen}
          phoneCallStatus={phoneCallStatus}
          phoneCountryList={phoneCountryList}
          setVisible={setIsPhoneModalOpen}
          onPhoneCallCancel={onPhoneCallCancel}
          onPhoneCallClick={onPhoneCallClick}
        /> */}
      </Modal>

      <Modal opened={isCrcModalOpen} onClose={() => setIsCrcModalOpen(false)} title="H323/SIP Room">
        <CRCCallOutModal visible={isCrcModalOpen} setVisible={setIsCrcModalOpen} />
      </Modal>
    </div>
  );
};

export default MicrophoneButton;
