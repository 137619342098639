import React, { useEffect, useState } from 'react';
import { Button, Grid, Text, Box, Textarea } from '@mantine/core';
import './AudioRecorder.css';
import axios from '../../utils/axios';
import { IconInfoCircleFilled } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import { getEHRTokenFromLocalStorage } from '../../utils/util';
import { useMedplum } from '@medplum/react';

interface ClinicalNotesProps {
  clinicalNotes: any;
  appointmentId: any;
  documentId: any;
  handleClose: () => void;
}

const ClinicalNotes: React.FC<ClinicalNotesProps> = ({ clinicalNotes, appointmentId, documentId, handleClose }) => {
  const medplum = useMedplum();
  const [clinicalNote, setClinicalNote] = useState('');
  const [isTextareaEdited, setIsTextareaEdited] = useState(false);

  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setClinicalNote(event.currentTarget.value);
    setIsTextareaEdited(true);
  };

  const handleSubmit = async () => {
    let token = await medplum.getAccessToken();

    let postData: any = {};
    postData.clinicalnote = clinicalNote;
    postData.appointmentId = appointmentId;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ehr_token: getEHRTokenFromLocalStorage(),
      },
    };

    let updateBody: any = {};
    updateBody.clinicalnote = clinicalNote;
    updateBody.documentId = documentId;

    try {
      await axios.put(`/api/pragmaconnect/update-clinical-note`, updateBody, config);
    } catch (error) {
      console.log(error);
    }
    let clinicalNoteResponse;
    const organizations = await medplum.searchResources('Organization', '_count=1000')
    for (const org of organizations || []) {
      const hasEpicExtension = org?.extension?.find((ext: any) => ext?.ehr === 'EPIC');
      if (hasEpicExtension) {
        clinicalNoteResponse = await axios.post('/ehr/epic/create-clinical-note-appointment', { ...postData, organization_name: org.name }, config);
        break;
      }
    }
    if (clinicalNoteResponse && clinicalNoteResponse?.data?.docRefId) {
      showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });
      handleClose();
    } else {
      showNotification({ color: 'green', message: 'Your Clinical Note has been submitted successfully.' });
      handleClose();
    }
  };

  useEffect(() => {
    setClinicalNote(clinicalNotes);
  }, []);

  return (
    <div>
      <Grid>
        <Grid.Col md={12} lg={12}>
          <Grid sx={{ alignItems: 'center', padding: '14px 8px 25px' }}>
            <IconInfoCircleFilled size={20} style={{ marginRight: '5px', color: '#828282' }} />
            <Text component="span" sx={{ fontWeight: 500 }}>
              User can edit clinical notes directly.
            </Text>
          </Grid>
          <Box>
            <Textarea
              value={clinicalNote}
              minRows={15}
              placeholder="Clinical assessment transcript"
              onChange={handleTextareaChange}
            />
            <Grid sx={{ padding: '30px 10px 0' }}>
              <Button
                sx={{
                  background: isTextareaEdited ? '#3673B6' : '#BDBDBD',
                  color: '#FCFCFD',
                  fontWeight: 600,
                  marginRight: '10px',
                  '&:hover': {
                    background: isTextareaEdited ? '#3673B6' : '#BDBDBD',
                  },
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                sx={{
                  background: '#3673B6',
                  fontWeight: 600,
                  '&:hover': {
                    background: '#3673B6',
                  },
                }}
                onClick={handleSubmit}
              >
                Submit to EHR
              </Button>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default ClinicalNotes;
