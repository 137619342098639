import { Box, UnstyledButton } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import './Pagination.css';

interface CustomPaginationProps {
  total: number;
  onChange: (page: number) => void;
  value?: number;
}

const CustomPagination = ({ total, onChange, value = 1 }: CustomPaginationProps): JSX.Element => {
  return (
    <Box className="pagination-container">
      <UnstyledButton
        onClick={() => onChange(value - 1)}
        disabled={value <= 1}
        className={`pagination-button previous ${value <= 1 ? 'disabled' : ''}`}
      >
        <IconChevronLeft size={18} />
        Previous
      </UnstyledButton>

      <Box className="pagination-numbers">
        {Array.from({ length: total }, (_, i) => i + 1).map((page) => (
          <UnstyledButton
            key={page}
            onClick={() => onChange(page)}
            className={`pagination-number ${page === value ? 'active' : ''}`}
          >
            {page}
          </UnstyledButton>
        ))}
      </Box>

      <UnstyledButton
        onClick={() => onChange(value + 1)}
        disabled={value >= total}
        className={`pagination-button next ${value >= total ? 'disabled' : ''}`}
      >
        Next
        <IconChevronRight size={18} />
      </UnstyledButton>
    </Box>
  );
};

export default CustomPagination;
