import { useEffect, useState } from 'react';
import { Group, Select } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendar, IconX, IconChevronDown } from '@tabler/icons-react';
import { useMedplumContext } from '@medplum/react';
import { getFullName } from '../../utils/util';
import './TelehealthFilters.css';

interface Filters {
  date?: string;
  location?: string;
  practitioner?: string;
  patient?: string;
}

interface TelehealthFiltersProps {
  onApplyFilters: (filters: Filters) => void;
  onCloseFilters: (isOpen: boolean) => void;
}

const TelehealthFilters: React.FC<TelehealthFiltersProps> = ({ onApplyFilters, onCloseFilters }) => {
  const { medplum } = useMedplumContext();
  const isSuperAdmin = medplum.isSuperAdmin();
  const [practitioners, setPractitioners] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedPractitioner, setSelectedPractitioner] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');

  const fetchPatients = (searchTerm = '') => {
    medplum.get(`/fhir/R4/Patient?name=${searchTerm}&_count=10`).then((response) => {
      const practitionerOptions = response.entry.map((entry: any) => ({
        value: entry.resource.id,
        label: getFullName(entry.resource?.name[0]) || entry.resource?.name[0]?.text || 'Unknown',
      }));
      setPatients(practitionerOptions);
    });
  };

  const fetchPractitioners = (searchTerm = '') => {
    medplum.get(`/fhir/R4/Practitioner?name=${searchTerm}&_count=10`).then((response) => {
      const practitionerOptions = response.entry.map((entry: any) => ({
        value: entry.resource.id,
        label: getFullName(entry.resource?.name[0]) || entry.resource?.name[0]?.text || 'Unknown',
      }));
      setPractitioners(practitionerOptions);
    });
  };

  const fetchLocations = (searchTerm = '') => {
    medplum.get(`/fhir/R4/Location?name=${searchTerm}&_count=10`).then((response) => {
      const locationOptions = response.entry.map((entry: any) => ({
        value: entry.resource.id,
        label: entry.resource.name || 'Unnamed Location',
      }));
      setLocations(locationOptions);
    });
  };

  const handleApplyFilters = () => {
    const filters = {
      practitioner: selectedPractitioner,
      location: selectedLocation,
      patient: selectedPatient,
      date: selectedDate ? selectedDate.toISOString() : '',
    };
    onApplyFilters(filters);
  };

  useEffect(() => {
    handleApplyFilters();
  }, [selectedPractitioner, selectedLocation, selectedPatient, selectedDate]);

  return (
    <div style={{ position: 'relative' }}>
      <IconX
        className="close-icon"
        onClick={() => {
          onCloseFilters(false);
        }}
      />
      <Group bg="#F9F5FF" p="sm" mb="lg">
        {isSuperAdmin && (
          <Select
            label="Organization"
            placeholder="Search"
            w={200}
            data={locations}
            rightSection={<IconChevronDown className="select-right-icon" />}
            onFocus={() => fetchLocations()}
            onChange={(value: string) => setSelectedLocation(value)}
            onSearchChange={(value) => fetchLocations(value)}
            classNames={{
              input: 'select-input',
              label: 'select-label',
            }}
            searchable
            clearable
          />
        )}
        {isSuperAdmin && (
          <Select
            label="Provider"
            placeholder="Search"
            w={200}
            data={practitioners}
            rightSection={<IconChevronDown className="select-right-icon" />}
            onFocus={() => fetchPractitioners()}
            onChange={(value: string) => setSelectedPractitioner(value)}
            onSearchChange={(value) => fetchPractitioners(value)}
            classNames={{
              input: 'select-input',
              label: 'select-label',
            }}
            searchable
            clearable
          />
        )}
        <Select
          label="Patient"
          placeholder="Search"
          w={200}
          data={patients}
          rightSection={<IconChevronDown className="select-right-icon" />}
          onFocus={() => fetchPatients()}
          onChange={(value: string) => setSelectedPatient(value)}
          onSearchChange={(value) => fetchPatients(value)}
          classNames={{
            input: 'select-input',
            label: 'select-label',
          }}
          searchable
          clearable
        />

        <DateInput
          label="Date"
          placeholder="Select"
          w={200}
          maw={400}
          value={selectedDate}
          valueFormat="MM/DD/YYYY"
          onChange={setSelectedDate}
          icon={<IconCalendar className="calender-icon" />}
          classNames={{
            input: 'date-input',
            label: 'date-input-label',
            calendarHeader: 'date-input-calendar-header',
            calendarHeaderLevel: 'date-input-calendar-header-level',
            yearsList: 'date-input-years-list',
            monthsList: 'date-input-months-list',
            day: 'date-input-day',
          }}
          clearable
        />
      </Group>
    </div>
  );
};

export default TelehealthFilters;
