import React, { useEffect, useState } from 'react';
import { Paper, Button, Group, Tabs, createStyles,Box, Text } from '@mantine/core';
import { formatSearchQuery, Operator, SearchRequest } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { Appointment, Bundle } from '@medplum/fhirtypes';
import TitleComponent from '../TitleComponent';
import TelehealthFilters from './TelehealthFilters';
import TelehealthTable from './TelehealthTable';
import { TelehealthDrawer } from './TelehealthDrawer';
import { useNavigate } from 'react-router-dom';
import './Telehealth.css';
import TelehealthAlert from './TelehealthAlert';
import CustomPagination from '../../components/CustomPagination';

const useStyles = createStyles(() => ({
  tabs: {
    '& .mantine-Tabs-tab': {
      fontSize: '12px',
      color: '#344054',
      marginRight: '0px',
      fontFamily: 'Inter',
      fontWeight: 600,
      borderRadius: '0',
      paddingTop: '8px !important',
      paddingBottom: '8px !important',
      paddingLeft: '10px !important',
    },
    '& button[data-active]': {
      color: '#182230',
      background: '#F9FAFB !important',
      fontWeight: 600,
      fontSize: '13px',
      borderRadius: '0',
      paddingTop: '8px !important',
      paddingBottom: '8px !important',
      paddingLeft: '10px !important',
      paddingRight: '10px !important',
    },
    '& .mantine-Tabs-tabsList': {
      borderBottom: '1px solid #EAECF0',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      display: 'flex',
      gap: '0',
    },

    '& .mantine-Tabs-tab:first-child': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '& .mantine-Tabs-tab:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },

  tabPanel: {
    display: 'block',
    flexDirection: 'row',
    alignItems: 'center',
  },

  badge: {
    minWidth: 'auto',
    minHeight: '25px',
    color: '#000000',
    textTransform: 'capitalize',
    fontWeight: 600,
    padding: '0 0.8rem',
  },

  pagination: {
    '& button': {
      border: 'none',
    },
    '& button[data-active]': {
      color: '#1b5390  ',
      background: 'none !important',
      backgroundColor: 'none !important',
      borderBottom: '0px !important',
      fontWeight: 700,
    },
  },
}));

const Telehealth: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Bundle<Appointment> | undefined>(undefined);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [activeTab, setActiveTab] = useState('all');
  const [advancedFilters, setAdvancedFilters] = useState<any>([]);
  const [activeButton, setActiveButton] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const filterTabs = [
    { label: 'All', value: 'all' },
    { label: 'Pre-Booked', value: 'pending' },
    { label: 'In Office', value: 'in-office' },
    { label: 'Completed', value: 'fulfilled' },
    { label: 'Cancelled', value: 'cancelled' },
  ];

  const fetchData = async (): Promise<void> => {
    setIsLoading(true);
    let filters = [
      { code: 'service-type', operator: Operator.EQUALS, value: 'Telehealth' },
      { code: '_sort', operator: Operator.EQUALS, value: '-date' },
    ];
    if (activeTab === 'in-office') {
      filters = [{ code: 'service-type', operator: Operator.EQUALS, value: 'In Office' }];
    }

    filters = advancedFilters.length ? [...filters, ...advancedFilters] : filters;

    const query: SearchRequest = {
      resourceType: 'Appointment',
      filters: filters,
      offset: offset,
      count: itemsPerPage,
      total: 'accurate',
    };

    if (activeTab !== 'all' && activeTab !== 'in-office') {
      query.filters?.push({ code: 'status', operator: Operator.EQUALS, value: activeTab });
    }

    try {
      const response: any = await medplum.readResource('Appointment', formatSearchQuery(query));
      setData(response.entry);
      setTotalItems(response.total ?? 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAppointmentStatuses = async (): Promise<void> => {
    try {
      const body = {
        url: 'http://hl7.org/fhir/ValueSet/appointmentstatus|4.0.1',
        filter: '',
      };

      const response: any = await medplum.get(`/fhir/R4/ValueSet/$expand?url=${body.url}&filter=${body.filter}`);
      if (response?.expansion?.contains) {
        const statuses = response.expansion.contains;
        setStatuses(statuses);
      }
    } catch (error) {
      console.error('Error fetching statuses:', error);
    }
  };

  useEffect(() => {
    fetchData().catch((error) => console.error('Error fetching data:', error));
    getAppointmentStatuses().catch((error) => console.error('Error fetching statuses:', error));
  }, [offset, medplum, activeTab, advancedFilters]);

  const handlePageChange = (page: number): void => {
    setOffset((page - 1) * itemsPerPage);
  };

  const handleRefresh = (): void => {
    setOffset(0);
    setActiveTab('all');
    fetchData().catch((error) => console.error('Error fetching data:', error));
  };

  const handleTabChange = (newTab: string): void => {
    setActiveTab(newTab);
  };

  const handleFilters = (filters: any): void => {
    const { date, location, practitioner, patient } = filters;
    const practitionerReference = practitioner ? `Practitioner/${practitioner}` : '';
    const locationReference = location ? `Location/${location}` : '';
    const patientReference = patient ? `Patient/${patient}` : '';
    const newDate = date ? new Date(date) : null;
    const nextDate = newDate ? new Date(newDate.setDate(newDate.getDate() + 1)).toISOString() : null;

    const updatedFilters = [
      { code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: date },
      { code: 'date', operator: Operator.LESS_THAN, value: nextDate },
      { code: 'location', operator: Operator.EQUALS, value: locationReference },
      { code: 'practitioner', operator: Operator.EQUALS, value: practitionerReference },
      { code: 'patient', operator: Operator.EQUALS, value: patientReference },
    ]?.filter((filter) => filter.value);

    setAdvancedFilters(updatedFilters);
  };

  const handleStartCall = (appointmentId: string): void => {
    if (!appointmentId) {
      console.error('Appointment ID not found');
      return;
    }
    handleRefresh();
    setIsModalOpen(false);
    navigate(`/Telehealth/${appointmentId}/meeting`);
  };

  const handleAddNew = (): void => {
    setActiveButton('AddNew');
    setIsModalOpen(true);
  };

  const handleFiltersClick = (): void => {
    setActiveButton('Filters');
    setIsFilterOpen(true);
  };

  return (
    <>
      <TitleComponent title="Telehealth" />
      <TelehealthAlert handleStartCall={handleStartCall} />
      <Paper className="telehealthContainerBorder">
        <Box m="md" mb={0} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text className={`telehealth-title`}>Telehealth</Text>

          <Group>
            <Button
              className={`button-default ${activeButton === 'Filters' ? 'button-active' : ''}`}
              onClick={handleFiltersClick}
            >
              Filters
            </Button>

            <Button
              className={`button-default ${activeButton === 'AddNew' ? 'button-active' : ''}`}
              onClick={handleAddNew}
            >
              <img
                src="/img/icons/plus.svg"
                alt="Add Icon"
                style={{
                  width: '18px',
                  height: '18px',
                  marginRight: '4px',
                  filter: activeButton === 'AddNew' ? 'brightness(0) invert(1)' : 'none',
                }}
              />
              Add New
            </Button>
          </Group>
        </Box>
        <div
          style={{
            borderBottom: '1px solid #EAECF0',
            width: '100%',
            marginTop: '20px',
          }}
        ></div>
        {isFilterOpen && (
          <TelehealthFilters
            onApplyFilters={handleFilters}
            onCloseFilters={(isFilterOpen) => {
              setIsFilterOpen(isFilterOpen);
              setActiveButton(isFilterOpen ? 'Filters' : '');
            }}
          />
        )}
        <Box style={{ marginTop: '0px', padding: '0px' }} className="resource-tab-panel patient-list">
          <Tabs mt="md" variant="pills" value={activeTab} className={classes.tabs} onTabChange={handleTabChange}>
            <Tabs mt="md" variant="pills" value={activeTab} className={classes.tabs} onTabChange={handleTabChange}>
              <Tabs.List
                w="min-content"
                style={{
                  marginTop: '0px',
                  marginLeft: '10px',
                }}
                className="tabs-list"
              >
                {filterTabs.map((tab) => (
                  <Tabs.Tab key={tab.value} value={tab.value} className="tabs-inline-style">
                    {tab.label}
                  </Tabs.Tab>
                ))}
              </Tabs.List>

              {filterTabs.map((tab) => (
                <Tabs.Panel key={tab.value} value={tab.value} pt="md" className={classes.tabPanel}>
                  <TelehealthTable
                    data={data}
                    isLoading={isLoading}
                    statuses={statuses}
                    handleStartCall={(value) => handleStartCall(value)}
                  />
                </Tabs.Panel>
              ))}
            </Tabs>
          </Tabs>

          <CustomPagination
            total={Math.ceil(totalItems / itemsPerPage)}
            onChange={handlePageChange}
            value={Math.floor(offset / itemsPerPage) + 1}
          />
        </Box>

        <TelehealthDrawer
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onStartCall={(value) => handleStartCall(value)}
        />
      </Paper>
    </>
  );
};

export default Telehealth;
