import React from 'react';
import classNames from 'classnames';
import { SharePrivilege } from '@zoom/videosdk';
import { IconLock, IconLockCancel, IconScreenShare } from '@tabler/icons-react';
import { Button, Tooltip } from '@mantine/core';

interface ScreenShareButtonProps {
  sharePrivilege: SharePrivilege;
  isHostOrManager: boolean;
  onSharePrivilegeClick?: (privilege: SharePrivilege) => void;
  onScreenShareClick: () => void;
}

interface ScreenShareLockButtonProps {
  isLockedScreenShare: boolean;
  onScreenShareLockClick: () => void;
}

const ScreenShareButton: React.FC<ScreenShareButtonProps> = ({
  onScreenShareClick,
}) => {

  return (
    <Button
        className={classNames('screen-share-button', 'vc-button')}
        size="sm"
        radius='xl'
        color='white'
        onClick={onScreenShareClick}
        > <IconScreenShare color='#868686' stroke={1.5}/>
    </Button>
  );
};

const ScreenShareLockButton: React.FC<ScreenShareLockButtonProps> = ({
  isLockedScreenShare,
  onScreenShareLockClick,
}) => {
  return (
    <Tooltip label={isLockedScreenShare ? 'Unlock screen share' : 'Lock screen share'}>
      <Button
        className="screen-share-button"
        leftIcon={isLockedScreenShare ? <IconLock color='#868686' stroke={1.5} /> : <IconLockCancel color='#868686' stroke={1.5} />}
        size="large"
        onClick={onScreenShareLockClick}
      />
    </Tooltip>
  );
};

export { ScreenShareButton, ScreenShareLockButton };
