import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Center, Grid, createStyles } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import axios from '../utils/axios';
import { getEHRTokenFromLocalStorage } from '../utils/util';
import { SSOButtons } from './CustomSSOButtons';

const useStyles = createStyles((theme) => ({
  logoImg: {
    height: '40px',
    width: '100%',
    padding: '5px',
  },

  ssoButton: {
    width: '200px',
    border: '1px solid #ccc',
    padding: '10px 0',
    marginBottom: '10px',
    backgroundColor: '#F6FAFD',
    color: '#2f67ad',
    height: '50px',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#F6FAFD',
      border: '1px solid #2f67ad',
    },
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  paddingMobile: {
    [theme.fn.smallerThan('sm')]: {
      padding: '25px',
    },
  },
  mainDarkBg: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    margin: '0',
  },
}));

const SSO = () => {
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const medplum = useMedplum();
  const [startLogin, setStartLogin] = useState(false);
  const [organizationsComplete, setOrganizationsComplete] = useState();

  const getAccessToken = async () => {
    try {
      let formbody: any = {};
      const uri = window.location.href.split('?')[0];
      if (localStorage.getItem('EMR_CODE') === 'EPIC') {
        const organization: any = localStorage.getItem('Organization');
        const orgObject = JSON.parse(organization);
        const orgConfig = orgObject.resource.extension.find((item: any) => item.type === 'provider');
        formbody = {
          code,
          redirectUrls: uri,
          tokenEndpoint: orgConfig.tokenUrl,
          scope: orgConfig.scope,
          clientId: orgConfig.clientId,
          grant_type: 'authorization_code',
          client: localStorage.getItem('EMR_CODE'),
          apiUrl: orgConfig.apiUrl,
          organization_name: orgObject?.resource?.name
        };
      }
      if (formbody.tokenEndpoint) {
        const res = await axios.post(`/api/sso/get-ehr-token-practioner`, formbody);
        localStorage.setItem('ehr_token', JSON.stringify(res));
        localStorage.setItem('patient_id', res.data.patient);
        createPatientAndNavigate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrgs = async () => {
    try {
      const response = await medplum.get('/fhir/R4/Organization?_count=100');
      setOrganizationsComplete(response.entry);
    } catch (error) {
      console.error(error);
    }
  };

  async function createPatientAndNavigate(): Promise<void> {
    const organization: any = localStorage.getItem('Organization');
    const orgObject = JSON.parse(organization);
    let postData: any = {};
    postData.patient_id = localStorage.getItem('patient_id');
    postData.organization_name = orgObject?.resource?.name;
    let token = await medplum.getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ehr_token: getEHRTokenFromLocalStorage(),
      },
    };
    axios
      .post('/ehr/epic/save-patient', postData, config)
      .then((response: any) => {
        localStorage.removeItem('patient_id');
        const patientId = response.data.patient.id;
        postData.ehr_type = localStorage.getItem('EMR_CODE');
        axios.post('/api/pragmaconnect/import-patient-details', postData, config).then((response: any) => {
          console.log('Started import');
        });
        const path = `/Patient/patient-dashboard/${patientId}`;
        const uri = window.location.href.split('/')[0];
        window.open(`${uri}${path}`, '_self');
      })
      .catch((error: any) => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      fetchOrgs();
      try {
        if (code && !startLogin) {
          if (isMounted) {
            setStartLogin(true);
          }

          await getAccessToken();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!startLogin) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [code, state, startLogin]);

  return (
    <Grid className={classes.mainDarkBg}>
      <Grid.Col className={classes.paddingMobile}>
        <Center p={{ xs: 'lg', md: 'lg' }} style={{ alignContent: 'center', justifyContent: 'center' }}>
          <Grid className="sso-card">
            <Grid.Col xs={12} mb="lg">
              <SSOButtons organizationList={organizationsComplete} />
            </Grid.Col>
          </Grid>
        </Center>
      </Grid.Col>
    </Grid>
  );
};

export default SSO;
