export const STATUS_COLORS = {
    noshow: '#FF6347',
    proposed: '#17B26A',
    active: '#17B26A',
    rejected: '#EF6820',
    inactive: '#EF6820',
    'entered-in-error': '#EF6820',
    pending: '#FFD274',
    cancelled: '#EF6820',
    booked: '#17B26A',
    fulfilled: '#17B26A',
    arrived: '#17B26A',
    'checked-in': '#17B26A',
    waitlist: '#17B26A',
} as const;


export const STATUS_BACKGROUND_COLORS = {
    noshow: '#FF6347',
    proposed: '#ECFDF3',
    active: '#ECFDF3',
    rejected: '#FEF6EE',
    inactive: '#FEF6EE',
    'entered-in-error': '#FEF6EE',
    pending: '#FEF6EE',
    cancelled: '#FEF6EE',
    booked: '#ECFDF3',
    fulfilled: '#ECFDF3',
    arrived: '#ECFDF3',
    'checked-in': '#ECFDF3',
    waitlist: '#ECFDF3',
} as const;

export const STATUS_BORDER_COLORS = {
    noshow: '#FF6347',
    proposed: '#ABEFC6',
    active: '#ABEFC6',
    rejected: '#F9DBAF',
    inactive: '#F9DBAF',
    'entered-in-error': '#F9DBAF',
    pending: '#F9DBAF',
    cancelled: '#F9DBAF',
    booked: '#ABEFC6',
    fulfilled: '#ABEFC6',
    arrived: '#ABEFC6',
    'checked-in': '#ABEFC6',
    waitlist: '#ABEFC6',
} as const;

export const STATUS_TEXT_COLORS = {
    noshow: '#FF6347',
    proposed: '#17B26A',
    active: '#17B26A',
    rejected: '#B93815',
    inactive: '#B93815',
    'entered-in-error': '#B93815',
    pending: '#FFD274',
    cancelled: '#B93815',
    booked: '#17B26A',
    fulfilled: '#17B26A',
    arrived: '#17B26A',
    'checked-in': '#17B26A',
    waitlist: '#17B26A',
} as const;

export type AppointmentStatus = "proposed | pending | booked | arrived | fulfilled | cancelled | noshow | entered-in-error | checked-in | waitlist";