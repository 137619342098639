import React from 'react';
import { Box, Group, Tabs } from '@mantine/core';
import { Appointment, Patient } from '@medplum/fhirtypes';
import './PatientDetails.css';
import TelehealthTranscription from './TelehealthComponents/TelehealthTranscription';

interface PatientDetailsProps {
  patient: Patient;
  appointment: Appointment;
  transcription: string;
  setIsRightSectionVisible?: any;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({
  patient,
  appointment,
  transcription,
  setIsRightSectionVisible,
}: PatientDetailsProps) => {
  return (
    <Group w={'100%'}>
      <Tabs color="#7F56D9" defaultValue="transcription" w={'100%'}>
        <Box display="flex" className="tabs-header">
          <Tabs.List className="tabs-list">
            <Tabs.Tab value="transcription">Transcription</Tabs.Tab>
            <Tabs.Tab value="notes" title="Waiting to Generate Notes..." disabled>
              Clinical Notes
            </Tabs.Tab>
            <Tabs.Tab value="chat" disabled>
              Chat
            </Tabs.Tab>
            {/* <Tabs.Tab value="attendees">Attendees</Tabs.Tab> */}
          </Tabs.List>
          <img
            src="../../img/icons/hide-button.svg"
            alt=""
            onClick={setIsRightSectionVisible}
            title="Close Sidebar"
            style={{ cursor: 'pointer' }}
          />
        </Box>
        <Tabs.Panel value="transcription" pt="xs" w={'100%'}>
          <TelehealthTranscription transcription={transcription} />
        </Tabs.Panel>
      </Tabs>
    </Group>
  );
};

export default PatientDetails;
