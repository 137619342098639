import { Box, Button } from '@mantine/core';
import './Components.css';


interface TelehealthEndCallModalProps {
  onLeaveClick: () => void;
  setisConfirmationModalOpen: (value: boolean) => void;
}

const TelehealthEndCallModal = (props: TelehealthEndCallModalProps) => {
  const { onLeaveClick, setisConfirmationModalOpen } = props;


  return (
    <>
      <Box
        h={'5.3rem'}
        style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
      >
        <Button
          className="telehealth-end-call-modal-button"
          variant="filled"
          color="red"
          onClick={() => {
            setisConfirmationModalOpen(true);
          }}
          fullWidth
        >
          End call for everyone
        </Button>
        <Button
          className="telehealth-end-call-modal-button"
          bg={'#D0D5DD'}
          c={'#334054'}
          variant="default"
          onClick={() => {
            setisConfirmationModalOpen(true);
            onLeaveClick();
          }}
          fullWidth
        >
          Leave call
        </Button>
      </Box>
    </>
  );
};

export default TelehealthEndCallModal;
